@media screen and (min-width: 1140px) {

  .zoomInLeft {
    animation-name: zoomInLeft;
  }

  .zoomInRight {
    animation-name: zoomInRight;
  }

  .zoomOutLeft {
    animation-name: zoomOutLeft;
  }

  .zoomOutRight {
    animation-name: zoomOutRight;
  }

  .body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .header {
    &__items {
      justify-content: center;
    }
    &__item {
      margin: 0 10px;
    }
  }

  .main {
    flex-grow: 1;
    &__header {
      padding: 60px 0;
      margin-bottom: 60px;
    }
    &__heading {
      font-size: 60px;
      font-weight: 100;
      margin-bottom: 15px;
    }
    &__subheading {
      font-size: $x-large;
    }
    &__text {
      line-height: 1.6;
      font-size: $large;
    }
  }

  .hidden-lg {
    display: none;
  }

  .container {
    width: 1140px;
    margin: auto;
  }

  .container-small {
    width: 800px;
    margin: auto;
  }

  .products {
    &__collection {
      display: flex;
      justify-content: space-between;
      padding: 55px 0;
    }
  }

  .product {
    flex-grow: 1;
    width: calc(100% / 3);
    box-sizing: border-box;
    padding: 5px;
    &__heading {
      font-size: 30px;
      padding: 10px 0;
    }
  }

  .services {
    &__collection {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 30px 0;
    }
  }

  .service {
    width: 50%;
    padding: 30px;
    &__image {
      margin-bottom: 15px;
    }
    &__heading {
      font-size: 30px;
      margin-bottom: 15px;
    }
    &__subheading {
      margin-bottom: 15px;
    }
  }

  .action {
    padding: 60px 0;
    &__heading {
      font-size: 24px;
    }
  }

  .slider {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    &__slides {
      position: relative;
      height: $slide-height;
    }
    &__links {
      margin: 30px 0;
    }
    &__link {
      display: inline-block;
      @include heading;
      font-size: $x-large;
      padding: 10px;
      margin: 0 5px;
      color: lightgrey;
      &--active {
        color: $text-color;
      }
    }
  }

  .slide {
    position: absolute;
    height: $slide-height;
    width: 100%;
    top: 0;
    left: 0;
    &__heading {
      font-size: $x-large;
      line-height: 1.6;
    }
    &__image {
      margin-bottom: 15px;
    }
    &__text {
      line-height: 1.6;
    }
    &--initial {
      display: none;
    }
  }

  .breadcrumbs {
    padding: 60px 0;
  }

  .jobs {
    padding: 60px 0;
  }

  .job {
    margin: 60px 0;
    &__heading {
      font-size: 30px;
    }
  }

  .thejob {
    padding: 60px 0;
    &__heading {
      @include heading;
      font-size: 30px;
      margin-top: 55px;
    }
    &__subheading {
      @include heading;
      font-size: 20px;
      font-weight: 600;
      margin-top: 15px;
    }
    &__push {
      margin-top: 15px;
    }
    &__textteaser {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .contact {
    padding: 60px 0;
    &__heading {
      margin-bottom: 60px;
    }
  }
  
  .office {
    max-width: 100%;
    margin-bottom: 60px;
  }
  
  .site-notice {
    padding: 60px 0;
    &__heading {
      margin-bottom: 60px;
    }
  }

  .disclaimer {
    margin-bottom: 60px;
    &__heading {
      margin-bottom: 60px;
    }
  }

  .footer {
    padding: 30px 0;
  }

  .external {
    &__items {
      margin-bottom: 25px;
    }
    &__item {
      margin: 0 30px;
    }
    &__link {
      padding: 0 5px;
      font-size: 40px;
      &:hover {
        color: $text-color-light;
      }
    }
  }

  .diamond {
    &--footer {
      margin-bottom: 30px;
    }
  }
}
