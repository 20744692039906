@mixin header-icon {
  color: transparentize(white, 0.3);
  padding: 0 20px;
  height: $header-height;
  line-height: $header-height;
  display: block;
  font-size: 24px;
}

@media screen and (max-width: 1140px) {
    
  .header {
    &__items {
      justify-content: space-between;
    }
    &__icon {
      @include header-icon;
    }
  }

  .body {
    transition: transform 0.5s ease-in-out;
    &--mobile-menu-active {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .mm {
    background-color: #001330;
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: transform 1s ease-in-out;
    &__icon {
      @include header-icon;
      position: absolute;
      top: 0;
      right: 0;
    }
    &__items {
      margin: auto;
    }
    &__item {
      border-bottom: 1px solid transparentize(white, 0.7);
      &:last-child {
        border-bottom: none;
      }
    }
    &__link {
      color: transparentize(white, 0.2);
      font-size: $large;
      display: block;
      padding: 20px 40px;
      &--current {
        color: white;
      }
    }
  }

  .main {
    &__header {
      padding: 60px 10px;
      margin-bottom: 40px;
    }
    &__heading {
      font-size: 45px;
      font-weight: 100;
      margin-bottom: 10px;
    }
    &__subheading {
      font-size: $large;
      padding: 0 10px;
    }
    &__text {
      padding: 0 10px;
      max-width: 570px;
      margin: auto;
    }
  }

  .products {
    &__collection {
      padding: 40px 0;
    }
  }

  .product {
    max-width: 380px;
    margin: auto;
    margin-bottom: 40px;
    padding: 0 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &__heading {
      font-size: 25px;
      padding: 5px;
    }
  }
  
  .services {
    &__collection {
      padding: 40px 0;
    }
  }

  .service {
    max-width: 570px;
    padding: 0 10px;
    margin: auto;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &__image {
      margin-bottom: 15px;
    }
    &__heading {
      font-size: 25px;
      margin-bottom: 15px;
    }
    &__subheading {
      margin-bottom: 15px;
    }
  }

  .contact {
    padding: 40px 10px;
    &__heading {
      margin-bottom: 40px;
    }
  }

  .site-notice {
    padding: 40px 10px;
    &__heading {
      margin-bottom: 40px;
    }
  }

  .disclaimer {
    padding: 0 10px 40px 10px;
    &__heading {
      margin-bottom: 40px;
    }
  }
  
  .office {
    max-width: 100%;
  }

  .action {
    padding: 60px 10px;
    &__heading {
      font-size: $x-large;
    }
  }

  .footer {
    padding: 40px 0;
    &__text {
      padding: 0 5px;
    }
  }

  .external {
    &__items {
      margin-bottom: 30px;
    }
    &__item {
      margin: 0 10px;
    }
    &__link {
      padding: 0 20px;
      font-size: 40px;
      &:hover {
        color: $text-color-light;
      }
    }
  }

  .diamond {
    &--footer {
      margin-bottom: 20px;
    }
  }

  .slide {
    margin: auto;
    max-width: 570px;
    text-align: center;
    padding: 60px 10px;
    border-top: 1px solid $border-color;
    &:first-child {
      border-top: none;
    }
    &__image {
      max-width: 100%;
    }
  }

  .breadcrumbs {
    padding: 80px 10px;
  }

  .container-small {
    max-width: 570px;
    margin: auto;
  }
    
  .jobs {
    padding: 40px 0;
  }

  .job {
    margin: 40px 0;
    padding: 0 10px;
    &__heading {
      font-size: 25px;
    }
  }

  .thejob {
    padding: 40px 10px;
    &__heading {
      @include heading;
      font-size: 25px;
      margin-top: 40px;
    }
    &__subheading {
      @include heading;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    &__push {
      margin-top: 20px;
    }
    &__textteaser {
      text-align: center;
      font-size: 18px;
    }
  }

  .hidden-xs {
    display: none;
  }

}

