@import 'reset';

$text-color: #333;
$text-color-light: lighten($text-color, 20%);
$border-color: #e1e8ed;
$link-color: #428Aff;
$dark-blue: #001b43;
$olive: #5c6223;

$medium: 16px;
$small: 14px;
$x-small: 12px;
$large: 18px;
$x-large: 20px;

@mixin heading {
  font-family: 'Roboto', serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v17-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-v17-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/Roboto-Light.woff') format('woff');
}

@import url(/assets/font-awesome.min.css);

body {
  font-family: 'Open Sans', sans-serif;
  font-size: $medium;
  font-weight: 400;
  line-height: 1.5;
  color: $text-color;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}

.animated {
  animation-duration: 1.25s;
  animation-fill-mode: both;
}

$header-height: 55px;

.header {
  height: $header-height;
  line-height: $header-height;
  text-align: center;
  background-color: #001330;
  &__items {
    display: flex;
  }
  &__img {
    vertical-align: middle;
    max-height: 30px;
  }
  &__link {
    text-transform: uppercase;
    font-weight: 600;
    color: transparentize(white, 0.2);
    padding: 0 30px;
    height: $header-height;
    line-height: $header-height;
    display: block;
    &--current {
      color: white;
    }
  }
}

.main {
  &__header {
    text-align: center;
    background-image: url('/assets/abstract.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  }

  &__subheading {
    @include heading;
    color: transparentize(white, 0.1);
  }
  &__heading {
    @include heading;
    line-height: 1.4;
    text-shadow: 0 0 10px $dark-blue;
  }
  &__text {
    color: $text-color-light;
    text-align: justify;
  }

  &__link {
    color: $link-color;
  }

}

.footer {
  text-align: center;
  background-color: #f0f0f0;
  border-top: 1px solid $border-color;
  &__nav {
    margin-bottom: 30px;
  }
  &__items {
    display: flex;
    justify-content: center;
  }
  &__item {
    margin: 0 10px;
  }
  &__link {
    padding: 0 15px;
    display: block;
    font-weight: 600;
    font-size: $small;
    color: $text-color-light;
  }
  &__text {
    color: #a3a3a3;
    font-size: $small;
  }
  $count-width: 18px;
  &__count {
    display: inline-block;
    background-color: #ff3333;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    width: $count-width;
    height: $count-width;
    line-height: $count-width;
    color: white;
    font-size: 12px;
  }
}

.external {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__link {
    display: block;
    color: transparentize($text-color-light, 0.5);
    &:hover {
      color: $text-color-light;
    }
  }
}

.diamond {
  height: 32px;
  text-align: center;
  background: url(/assets/border.png) center left repeat-x;
  font-size: $small;
  font-weight: 600;
  & > span {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    position:relative;
    background-color: #f0f0f0;
    padding: 0 5px;
    border-left: 42px solid transparent;
    border-right: 42px solid transparent;
    border-image: url(/assets/diamonds.svg) 0 32 repeat;
  }
  &--footer {
    & > span {
      background-color: #bababa;
      border-image: url(/assets/diamonds_footer.svg) 0 32 repeat;
      color:white;
    }
  }
}

/*  contact.html  */

.contact {
  &__text {
    color: $text-color-light;
  }
  a {
    color: $link-color;
  }
}

.office {
  display: block;
  margin: auto;
  animation-delay: 0.5s;
}

.contactus {
  td {
  }
  td:first-child {
    padding-right: 10px;
  }
  a {
    color: $link-color;
  }
}


/*  index.html  */

.products {
  &__collection {
  }
}

.product {
  box-sizing: border-box;
  text-align: center;
  &:nth-child(2) {
    animation-delay: 0.25s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
  &__icon {
    font-size: 40px;
  }
  &__heading {
    @include heading;
    font-size: 30px;
    padding: 10px 0;
  }
  &__text {
    color: $text-color-light;
    margin-bottom: 15px;
  }
  &__link {
    color: $link-color;
    font-size: $large;
  }
}

.services {
  &__heading {
    text-align: center;
    height: 120px;
    line-height: 120px;
    @include heading;
    font-size: 30px;
  }
  &__collection {
  }
}

.service {
  box-sizing: border-box;
  &__image {
    max-width: 100%;
  }
  &__heading {
    @include heading;
    line-height: 1;
  }
  &__content {
    color: $text-color-light;
    p {
      margin-bottom: 15px;
      text-align: justify;

    }
  }
  &__list {
    margin-left: 1.5em;
    padding: 0;
    list-style-type: disc;
    list-style-position: outside;
    color: $text-color-light;
  }
}

/*  jobs/index.html  */

.action {
  text-align: center;
  border-top: 1px solid $border-color;
  &__heading {
    @include heading;
  }
  &__text {
    color: $text-color-light;
  }
  &__link {
    color: $link-color;
  }
}

/* Slider */

$slide-height: 560px;

.slide {
  &__heading {
    font-weight: 600;
  }
  &__text {
    color: $text-color-light;
  }
  &__link {
    color: $link-color;
  }
}

.breadcrumbs {
  border-top: 1px solid $border-color;
  &__item {
    display: inline-block;
    font-size: $small;
    &:after {
      content: '>';
      padding: 0 10px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &__link {
    color: $link-color;
  }
}

.jobs {
  &__footer {
    text-align: center;
  }
}

.job {
  &__kicker {
    color: $text-color-light;
    font-size: $small;
  }
  &__heading {
    @include heading;
    font-weight: 400;
  }
  &__text {
    color: $text-color-light;
  }
  &__link {
    color: $link-color;
  }
}

.thejob {
  &__subheading {
    font-weight: 600;
  }
  &__heading {
    @include heading;
  }
  &__text {
    color: $text-color-light;
  }
  &__push {
    color: $text-color-light;
  }
  &__link {
    color: $link-color;
  }
  &__list {
    margin-left: 1.5em;
    padding: 0;
    list-style-type: disc;
    list-style-position: outside;
    color: $text-color-light;
  }
}

/*  impressum.html  */

.site-notice {
  table,
  p {
    margin-bottom: 15px;
    color: $text-color-light;
  }
  &__list {
    margin-left: 1.5em;
    padding: 0;
    list-style-type: disc;
    list-style-position: outside;
    color: $text-color-light;
  }
  
}


@import 'onrooby.desktop';
@import 'onrooby.phone';
